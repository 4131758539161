/* d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3 */

.appLoginContainer { 
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;
}

.loginContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #fafafa
}

.loaderView {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.versionTag {
  text-align: end;
  font-size: 0.8rem;
  color: grey;
}