.logo {
  width: 200px;
  padding: 10px;
  margin: 0 auto;
}

.logo img {
  width: 100%;
}

.container {
  margin-top: 70px
}

.textField { 
  width:calc(100% - 121px);
}

.searchButton {
  height: 65px;
  width: 116px;
  margin-left: 5px !important;
}

.search {
  margin-top: 30px;
}

.benefits {
  width: 100%;
  margin: 50px auto;
}

.benefitCard {
  width: 100%;
  padding: 20px;
  margin-bottom: 10px;
  border:1px solid #b3bac5;
  box-sizing: border-box;
  border-radius: 5px;
}

.benefitCardTitle {
  margin-bottom: 10px;
}

@media (max-width: 600px) 
{
  .searchButton 
  {
    width: 100%;
    margin: 10px 0 0 0 !important;
  }.textField { 
    width:calc(100%);
  }
}